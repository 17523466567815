import React from 'react';
import UsesSection from 'components/sections/UsesSection';

function Sizing() {

    return (
        <UsesSection 
            title={"Clinician"}
            content="Service Lines, Disease Incidence, experience Plans, Key Driver time/hours of operatoins, Key Driver Utilization"
            link_backward="/uses/builder"
            link_forward="/uses/finance"
            media={<div className="uses-video">
            <video
                className="uses-video"
                canplay="this.play()"
                onloadeddata="this.play();"
                playsinline
                id="info-video"
                title="Video"
                onclick="this.play()"
                controls="controls"
                loop
            >
                <source type="video/mp4" src="https://storage.googleapis.com/media-buckets-prod-forsightdigital-com/videos/Clinician_Short.mp4"></source>
            </video>

        </div>}
        />
    )
}

export default Sizing;